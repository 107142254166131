import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import '../styles/prescription.css'

const prescriptionData = [
  {
    id: 1,
    stepNumber: '1.',
    step: 'Fill out the form',
  },
  {
    id: 2,
    stepNumber: '2.',
    step: 'Upload your prescription',
  },
  {
    id: 3,
    stepNumber: '3.',
    step: 'Get order delivered',
  },
]

const Prescription = () => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [prescriptionNumber, setPrescriptionNumber] = useState('');
  const [deliveryOption, setDeliveryOption] = useState("Delivery")
  // const [file, setFile] = useState('');

  const [statusMessage, setStatusMessage] = useState("");

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(event.target.firstName.value);
  //   console.log(event.target.lastName.value);
  //   console.log(event.target.file.value);
  //   console.log(event.target.deliveryOption.value);
  // }

  const sendEmail = (e) => {
    e.preventDefault();

    // We need to convert the file to base64 so we use the inbuilt javascript 
    // FileReader class
    // const reader = new FileReader();
    // reader.readAsDataURL(file);

    console.log("message sent");

    const SERVICE_ID = 'service_lle3sme';
    const TEMPLATE_ID = 'template_0haxu1h';
    const PUBLIC_KEY = 'mNiJeJ2XUJEujqJoH';

    //create a new object that contains dynamic template parms
    const templateParams = {
      user_first_name: firstName,
      user_last_name: lastName,
      user_phone_number: phoneNumber,
      user_email: email,
      user_date_of_birth: dateOfBirth,
      user_address: address,
      user_prescription_number: prescriptionNumber,
      user_delivery_or_pickup: deliveryOption,
      // use_file: reader.result,
      to_name: 'Dalwinder',
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        console.log("Email sent successfully", result);
        setStatusMessage("Email sent..")

        setTimeout(() => {
          setStatusMessage("")
        }, 5000) // Hide the message after 5 seconds

        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setDateOfBirth('');
        setPrescriptionNumber('');
        setDeliveryOption('');
        // setFile('');

      }, (error) => {
        console.log(error.text);

        setStatusMessage("Email Failed..")
        setTimeout(() => {
          setStatusMessage("")
        }, 5000) // Hide the message after 5 seconds
      });
  };

  const onOptionChange = e => {
    setDeliveryOption(e.target.value)
  }

  return (
    <div className='prescription'>
      <div className="secContainer container grid">

        <div className="prescriptionTitle">
          <h1>Prescription Refills & Transfer</h1>
        </div>

        <div className="prescriptionStepProcess">
          <div className="prescriptionProcessTitle">
            <h2>Simple 3 Step Process</h2>
          </div>
          <div className="prescriptionProcess">
            {
              prescriptionData.map(({ id, stepNumber, step }) => {
                return (
                  <div key={id} className="prescriptionStep">
                    <h2>{stepNumber}</h2>
                    <h3>{step}</h3>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="prescriptionForm">
          <div className="prescriptionFormTitle">
            <h2>Prescription Refills & Deliver</h2>
          </div>
          <form className="prescriptionRefill" onSubmit={sendEmail}>
            <input
              className="prescriptionInput"
              type="text"
              name="firstName"
              value={firstName}
              placeholder="First Name" required
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              className="prescriptionInput"
              type="text"
              name="lastName"
              value={lastName}
              placeholder="Last Name" required
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              className="prescriptionInput"
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              placeholder="Phone Number" required
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <input
              className="prescriptionInput"
              type="email"
              name="email"
              value={email}
              placeholder="Your Email" required
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="prescriptionInput"
              type="text"
              name="dateOfBirth"
              value={dateOfBirth}
              placeholder="Date Of Birth" required
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
            <input
              className="prescriptionInput"
              type="text"
              name="address"
              value={address}
              placeholder="Address" required
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              className="prescriptionInput"
              type="text"
              name="medicationDetails"
              value={prescriptionNumber}
              placeholder="Medication Name or prescription number"
              onChange={(e) => setPrescriptionNumber(e.target.value)}
            />

            <div className="radioButton">
              <input type="radio" name="deliveryOption" value="Delivery" id="delivery" checked={deliveryOption === "Delivery"} onChange={onOptionChange} />
              <label htmlFor="delivery">Delivery</label>
              <input type="radio" name="deliveryOption" value="Pick Up" id="pickup" checked={deliveryOption === "Pick Up"} onChange={onOptionChange} />
              <label htmlFor="pickup">Pick Up</label>
            </div>


            {/* <input
              className="chooseFile"
              type="file"
              value={file}
              name="file"
              onChange={(e) => setFile(e.target.value)}
            /> */}

            <div className="buttonAndStatus">
              <button
                className="btn btn1"
                type="submit"
              >
                Submit
              </button>
              <h2>
                {statusMessage}
              </h2>
            </div>
          </form>
        </div>

      </div>
    </div >
  )
}

export default Prescription;
