import React from 'react'
import '../styles/footer.css'

import { ImFacebook, ImLocation2 } from 'react-icons/im'
// import { BsTwitter, BsLinkedin} from 'react-icons/bs'
// import { AiFillInstagram } from 'react-icons/ai'
import { AiFillCopyrightCircle } from 'react-icons/ai'
// import { AiFillLinkedin } from 'react-icons/ai'
// import { FaLinkedinIn } from 'react-icons/fa'
import { BsFillTelephoneFill, BsInstagram } from 'react-icons/bs'
// import { MdLocationOn } from 'react-icons/md'
import { FaFax, FaPaperPlane } from 'react-icons/fa'
import { GiAlarmClock } from 'react-icons/gi'

//import images
import logo from '../Assets/pharmacy_logo.png'

const address = '4-198 Mcphillips Street, Winnipeg, MB R3E 2J9'
const emailAddress = 'mcphillipspharmacywpg@gmail.com'
const phoneNumber = '(204) 786 3465'
const faxNumber = '(204) 786 0846'


const workingHoursData = [
  {
    id: 1,
    day: 'Monday',
    time: '8am - 6pm',
  },
  {
    id: 2,
    day: 'Tuesday',
    time: '8am - 6pm',
  },
  {
    id: 3,
    day: 'Wednesday',
    time: '8am - 6pm',
  },
  {
    id: 4,
    day: 'Thursday',
    time: '8am - 6pm',
  },
  {
    id: 5,
    day: 'Friday',
    time: '8am - 6pm',
  },
  {
    id: 6,
    day: 'Saturday',
    time: '9am - 3pm',
  },
  {
    id: 7,
    day: 'Sunday',
    time: '10am - 4pm',
  }
]

const Footer = () => {

  return (
    <div className='footer'>
      <div className="secContainer container grid">

        <div className="footerLayout">
          <div className="logoDiv">
            <div className="footerLogo">
              <a href="#" className="logo">
                <img src={logo} alt="Image title" />
              </a>
            </div>
            <div className="socials flex">
              <a href="https://www.facebook.com/profile.php?id=100091143427506"><ImFacebook className="icon" /></a>
              {/* <a href="#"><BsTwitter className="icon" /></a> */}
              <a href="https://www.facebook.com/profile.php?id=100091143427506"><BsInstagram className="icon" /></a>
              {/* <a href="#"><FaLinkedinIn className="icon" /></a> */}
            </div>
          </div>

          <div className="contacts">
            <div className="contactTitle">
              Contact Us
            </div>
            <div className="contactBody">
              <div className="contactLine flex">
                <ImLocation2 className="icon" />
                <p>{address}</p>
              </div>
              <div className="contactLine flex">
                <FaPaperPlane className="icon" />
                <p>{emailAddress}</p>
              </div>
              <div className="contactLine flex">
                <BsFillTelephoneFill className="icon" />
                <p>{phoneNumber}</p>
              </div>
              <div className="contactLine flex">
                <FaFax className="icon" />
                <p>{faxNumber}</p>
              </div>
            </div>
          </div>

          <div className="hours container">
            <div className="hoursContent">
              <div className="hoursHeading">
                <GiAlarmClock className="icon" />
                <h2>Hours Of Operation</h2>
              </div>
              {
                workingHoursData.map(({ id, day, time }) => {
                  return (
                    <div key={id} className="hoursOfOperation">
                      <li key={id}>
                        <p>{day}</p>
                        <p>{time}</p>
                      </li>
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>
      </div>
      {/* <div class="copyright">
          <p> Copyright <span><AiFillCopyrightCircle className="icon"/></span> McPhillips Pharmacy. Designed by Sandeep Gill</p>
      </div> */}
      <div className="copyright">
        <div className="copyrightDisplay">
          <p> Copyright </p><p><AiFillCopyrightCircle className="icon" /></p> <p>McPhillips Pharmacy. All rights reserved.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
