import React from 'react'
import '../styles/banner.css'

const Banner = () => {
  return (
    <section className='banner'>
      <div className="secContainer container">

        <div className="bannerText">
          <h1 className="title">
            Exceptional Pharmacy Services for Your Health Needs
          </h1>
          <p className="subTitle">
            Our advanced pharmacy operates on the principle of collaborative healthcare, providing a wide array of services conveniently located in the heart of Winnipeg.
          </p>
        </div>

      </div>
    </section>
  )
}

export default Banner