import React from 'react'
/* import './ContactScreen.css' */
import NavBar from '../Components/Navbar';
import Banner from '../Components/Banner';
import Contact from '../Components/Contact';
import Footer from '../Components/Footer';

function ContactScreen () {
  return (
    <div className='ContactScreen'>
      <NavBar />
      <Banner />
      <Contact />
      <Footer />
    </div>
  )
}

export default ContactScreen;