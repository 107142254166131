import React from 'react'
import './App.css'

import HomeScreen from './Screens/HomeScreen';
import ServiceScreen from './Screens/ServiceScreen';
import PrescriptionScreen from './Screens/PrescriptionScreen';
import ContactScreen from './Screens/ContactScreen';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  return (
    <div className="app">
      <Router>
        {
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/Home" element={<HomeScreen />} />
            <Route path="/services" element={<ServiceScreen />} />
            <Route path="/Prescription" element={<PrescriptionScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
          </Routes>
        }
      </Router>
    </div>
  )
}

export default App
