import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";
import '../styles/navbar.css'

// import { AiFillCloseCircle } from 'react-icons/ai'
import { FaWindowClose } from 'react-icons/fa'
import { TbGridDots } from 'react-icons/tb'

import logo from '../Assets/pharmacy_logo.png'
// import logo from '../Assets/logo.png'

const Navbar = () => {

  //code to toggle/show navBar
  //set the value of className='navBar' as name active using useState
  const [active, setActive] = useState('navBar')

  const showNav = () => {
    setActive('navBar activeNavbar')
  }

  const removeNav = () => {
    setActive('navBar')
  }

  // code to add background color to the header when scroll down
  const [transparent, setTransparent] = useState('header')

  const addBg = () => {
    if (window.scrollY >= 15) {
      setTransparent('header activeHeader')
    }
    else {
      setTransparent('header')
    }
  }
  window.addEventListener('scroll', addBg)

  const scrollPage = () => {
    window.scrollTo(0, 0);
  }

  return (
    <section className='navBarSection'>
      <div className={transparent}>
        <div className="logoDiv">
          <NavLink className="logo" to="/Home" onClick={() => scrollPage()}>
            <img src={logo} alt="Image title" />
          </NavLink>
          {/* <a href="#" onClick={() => navigateTo('/Home')} className="logo">
            <img src={logo} alt="Image title" />
          </a> */}
        </div>

        <div className={active}>
          <div className="logoDiv">
            {/* <a href="#" className="logo">
              <img src={logo} alt="Image title" />
            </a> */}
            <NavLink className="logo" to="/Home" onClick={() => scrollPage()}>
              <img src={logo} alt="Image title" />
            </NavLink>
          </div>
          <ul className="navLists flex">
            <li className='navItem'>
              {/* <a href="#" onClick={() => navigateTo('/Home')} className="navLink">HOME</a> */}
              <NavLink to="/Home" onClick={() => scrollPage()} className={({ isActive }) =>
                isActive ? "activeNavLink" : "navLink"
              }>
                HOME
              </NavLink>
            </li>

            <li className='navItem'>
              {/* <a href="#" onClick={() => navigateTo('/Services')} className="navLink">SERVICES</a> */}
              <NavLink to="/Services" onClick={() => scrollPage()} className={({ isActive }) =>
                isActive ? "activeNavLink" : "navLink"
              }>
                SERVICES
              </NavLink>

            </li>

            <li className='navItem'>
              {/* <a href="#" onClick={() => navigateTo('/Prescription')} className="navLink">PRESCRIPTION</a> */}
              <NavLink to="/Prescription" onClick={() => scrollPage()} className={({ isActive }) =>
                isActive ? "activeNavLink" : "navLink"
              }>
                PRESCRIPTION REFILLS
              </NavLink>
            </li>

            <li className='navItem'>
              {/* <a href="#" onClick={() => navigateTo('/Contact')} className="navLink">CONTACT</a> */}
              <NavLink to="/Contact" onClick={() => scrollPage()} className={({ isActive }) =>
                isActive ? "activeNavLink" : "navLink"
              }>
                CONTACT
              </NavLink>
            </li>
          </ul>

          <div onClick={removeNav} className="closeNavbar">
            <FaWindowClose className="icon" />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon" />
        </div>

      </div>
    </section >
  )
}

export default Navbar