import React from 'react'
/* import './ServiceScreen.css' */
import NavBar from '../Components/Navbar';
import Banner from '../Components/Banner';
import Services from '../Components/Services';
import Footer from '../Components/Footer';

function ServiceScreen () {
  return (
    <div className='ServiceScreen'>
      <NavBar />
      <Banner />
      <Services />
      <Footer />
    </div>
  )
}

export default ServiceScreen;