import React from 'react'
/* import './PrescriptionScreen.css' */
import NavBar from '../Components/Navbar';
import Banner from '../Components/Banner';
import Prescription from '../Components/Prescription';
import Footer from '../Components/Footer';

function PrescriptionScreen () {
  return (
    <div className='prescriptionScreen'>
      <NavBar />
      <Banner />
      <Prescription />
      <Footer />
    </div>
  )
}

export default PrescriptionScreen;