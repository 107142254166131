import React from 'react';
import '../styles/home.css'

//import images
import img3 from '../Assets/home/image3.jpg'
import img7 from '../Assets/home/family.png'
import img8 from '../Assets/home/medicine.png'

const Home = () => {
  return (
    <div className='home'>
      {/* <div className="secContainer container grid"> */}
      <div className="secContainer container ">
        <div className="title">
          <h1>McPhillips Pharmacy</h1>
        </div>

        <div className="row1">
          <div className="col1">
            <img src={img3} alt="image1" />
          </div>
          <div className="col2">
            <h2 className="subTitle">Who We Are</h2>
            <p> McPhillips Pharmacy is a locally owned community pharmacy in
              Winnipeg Manitoba delivering excellent and reliable pharmaceutical
              services seven days a week. We are conveniently located on 4-198
              McPhillips Street just beside McPhillips Medical Clinic and walking
              distance from Health Sciences Center. We offer free medication
              delivery 7 days a week and provide on-site compounding services as
              well.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="rowCol1">
          <h2 className="rowTitle">Your family's health comes first.</h2>
          <p> We can help with product recommendations, prescription
            synchronization and immunizations.
          </p>
        </div>
        <div className="rowCol2">
          <img src={img7} alt="image2" />
        </div>
      </div>

      <div className="row">
        <div className="rowCol1">
          <h2 className="rowTitle">Taking medications correctly is a priority.</h2>
          <p> We can help you stay on top of your medications with online fills and refills,
            customized packaging, and therapy adjustments.
          </p>
        </div>
        <div className="rowCol2">
          <img src={img8} alt="image2" />
        </div>
      </div>

    </div>

  );
}

export default Home;

